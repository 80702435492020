<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-fw fa-truck"></i> Hersteller</h4>
                        </div>

                        <div class="card-tools">
                            <button v-b-popover.hover.top="'Neu laden'" type="button" class="mr-1 btn btn-default" @click="loadProducers">
                                <i class="fas fa-sync"></i>
                            </button>
                            <button type="button" class="btn btn-primary" @click="createModal" v-if="$auth.check('producers.create')">
                                <i class="fas fa-plus-circle"></i> Neuer Hersteller
                            </button>
                            <!-- <a href="#" class="btn btn-primary pull-right">Create User</a> -->
                        </div>
                    </div>

                    <div class="card-body">
                        
                        <b-row>
                            <b-col>
                                <vue-good-table 
                            :columns="columns"
                            :rows="producers"
                            :search-options="{ 
                                enabled: true
                            }"
                            :pagination-options="{
                                enabled: true,
                                mode: 'pages',
                                perPage: 20,
                                perPageDropdownEnabled: true,
                                nextLabel: 'Nächste',
                                prevLabel: 'Vorherige',
                                rowsPerPageLabel: 'Zeilen pro Seite',
                                ofLabel: 'von',
                                pageLabel: 'Seite',
                                allLabel: 'Alle',
                            }"

                            :select-options="{
                                enabled: true,
                                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                                selectionInfoClass: 'custom-class',
                                selectionText: 'Zeile(n) ausgewählt',
                                clearSelectionText: 'Abwählen',
                                disableSelectInfo: false, // disable the select info panel on top
                                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                            }"
                            @on-row-dblclick="onRowDoubleClick" 
                            styleClass="vgt-table condensed striped">

                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field == 'action'">
                                    <!-- <b-button size="xs" variant="success" class="mr-1"><i class="fas fa-truck"></i></b-button> -->
                                    <router-link v-b-popover.hover.bottom="'ansehen'" class="mr-1 btn btn-success btn-xs" :to="{name: 'producers.show', params: {id: props.row.id}}" v-if="$auth.check('producers.show')"><i class="fas fa-fw fa-eye"></i></router-link>
                                    <router-link v-b-popover.hover.bottom="'bearbeiten'" class="mr-1 btn btn-warning btn-xs" :to="{name: 'producers.details', params: {id: props.row.id}}" v-if="$auth.check('producers.edit')"><i class="fas fa-fw fa-edit"></i></router-link>
                                    <b-button v-b-popover.hover.bottom="'löschen'" size="xs" @click="deleteProducer(props.row.id)" variant="danger" v-if="$auth.check('producers.destroy')"><i class="fas fa-fw fa-trash"></i></b-button>
                                </span>
                                
                            </template>
                            
                        </vue-good-table>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="producerModal" title="Neuen Hersteller anlegen" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk">
                <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">
                    <!-- <div class="row">
                        <div class="col-md-8">
                            <div class="form-group">
                                <label for="company_id" class="control-label">Shop*</label>
                                <select v-model="form.company_id" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('company_id')}">
                                    <option v-for="company in companies" :key="company.id" :value="company.id">{{company.name}}</option>
                                </select>
                                <has-error :form="form" field="company_id"></has-error>
                            </div>
                        </div>
                    
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="active" class="control-label">Online anzeigen?*</label>
                                <select v-model="form.active" type="text" class="form-control" :class="{'is-invalid': form.errors.has('active')}">
                                    <option value="1">Ja</option>
                                    <option value="0">Nein</option>
                                </select>
                                <has-error :form="form" field="active"></has-error>
                            </div>
                        </div>

                    </div> -->

                    <div class="row">
                        <div class="col-md-12">
                            

                            <div class="form-group">
                                <label for="name" class="control-label">Name*</label>
                                <input v-model="form.name" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('name')}">
                                <has-error :form="form" field="name"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="number" class="control-label">Herstellernummer*</label>
                                <input v-model="form.number" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('number')}">
                                <has-error :form="form" field="number"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="description" class="control-label">Beschreibung</label>
                                <ckeditor :editor="editor" :config="editorConfig" v-model="form.description" :class="{'is-invalid': form.errors.has('description')}"></ckeditor>
                                <has-error :form="form" field="description"></has-error>
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-10">
                                        <div class="form-group">
                                            <label for="street" class="control-label">Straße*</label>
                                            <input v-model="form.street" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('street')}">
                                            <has-error :form="form" field="street"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="house_number" class="control-label">Nr.*</label>
                                            <input v-model="form.house_number" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('house_number')}">
                                            <has-error :form="form" field="house_number"></has-error>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="zipcode" class="control-label">PLZ*</label>
                                        <input v-model="form.zipcode" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('zipcode')}">
                                        <has-error :form="form" field="zipcode"></has-error>
                                    </div>

                                    <div class="col-md-8">
                                        <label for="city" class="control-label">Ort*</label>
                                        <input v-model="form.city" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('city')}">
                                        <has-error :form="form" field="city"></has-error>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="logo" class="control-label">Logo</label>
                                <!-- <input v-model="form.logo" type="file" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('logo')}"> -->
                                <b-form-file id="logo" v-model="form.logo" :class="{'is-invalid': form.errors.has('logo')}" />
                                <has-error :form="form" field="logo"></has-error>
                            </div>
                        </div>
                    </div>
                </form>
            </b-modal>

    </b-container>
</template>

<script>
import ClassicEditor from '../../assets/packages/ckeditor5/src/ckeditor';


export default {
    name: "ProducersIndex",
    title: "Hersteller",
    data() {
        return {
            form: new window.UploadForm({
                id: "",
                // company_id: "",
                name: "",
                number: "",
                description: "",
                street: "",
                house_number: "",
                zipcode: "",
                city: "",
                // active: "1",
                logo: [],
            }),
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            sortBy: "id",
            sortDesc: false,
            perPage: 20,
            currentPage: 1,
            filter: "",
            columns: [
                {field: "number", label: "Herstellernummer"},
                {field: "name", label: "Name"},
                {field: "slug", label: "SEO"},
                {field: "action", label: "Aktionen", tdClass: 'text-center',},
            ],
            producers: [],
            companies: [],
        }
    },

    methods: {
        onRowDoubleClick(params){
            this.$router.push({name: 'producers.details', params: {id: params.row.id}})
        },

        deleteSelectedProducers(){
            this.$swal({
                    title: "Möchtest du die selektierten Hersteller wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, LÖSCHEN!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .post("/producers/deleteMany", {data: this.selectedProducers})
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Hersteller erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.loadProducers();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.createProducer();
        },

        createModal() {
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("producerModal");
        },

        deleteProducer(id) {
            this.$swal({
                    title: "Möchtest du den Hersteller wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Löschen`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.form
                            .delete("/producers/" + id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Hersteller erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.loadProducers();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        createProducer() {
            this.$Progress.start();
            this.form
                .post("/producers")
                .then(() => {
                    this.$bvModal.hide("producerModal");
                    this.$swal({
                        icon: "success",
                        title: "Hersteller wurde angelegt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadProducers();
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },


        loadProducers() {
            this.$Progress.start();
            this.axios
                .get("/producers")
                .then((response) => {
                    this.producers = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadCompanies() {
            this.$Progress.start();
            this.axios
                .get("/companies")
                .then((response) => {
                    this.companies = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    created() {
        this.loadProducers();
        this.loadCompanies();
    },



}
</script>

<style>

</style>